import { Button, Container, TextField } from '@mui/material';
import React, { useState } from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InputAdornment from '@mui/material/InputAdornment';


const App = () => {
  const [phone, setPhone] = useState();
  return (
    <Container max-width="380" style={{marginTop: '50px', maxWidth: '500px'}}>
      <TextField id="phone" label="Telefono/Phone" variant="outlined" onChange={e => setPhone(e.target.value)}
        style={{width: '100%'}}
        inputProps={
            <InputAdornment position="start">
              <LocalPhoneIcon />
            </InputAdornment>
          }
       />

       <Button style={{width: '100%', marginTop: '10px'}} variant="contained" href={`https://api.whatsapp.com/send?phone=${phone}`}>
        Contact Whatsapp / Contactar por Whatsapp
       </Button>
    </Container>
  )
}

export default App;